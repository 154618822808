/*=============================================================================================
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Andrisam
==============================================================================================*/
@import "sass-lib";

@media all and (max-width: 767px) {

	.fxo-widget-iframe {right: 45px !important; bottom: 2px !important;}

	.wrapper, .container {width: auto; margin:0 20px;}

	.btn {
		height: 40px; line-height: 40px; padding: 0 20px;
		&.v2 {height: 40px; line-height: 35px; padding: 0 25px;}
		&.medium {padding:0 45px; font-size: 14px;}
		&.abs {height: 36px; line-height: 32px;}
		&.green-outline {
			height: 36px; line-height: 32px; padding: 0 25px;
		}
	}

	header {
		height: 60px;
		.menu-top {
			margin: 20px 0 0 0;
			a {
				height: 20px; line-height: 20px; font-size: 12px;
				&.info {display: none;}
			}
			.lang {
				.act {
					padding-top: 0; margin-top: -2px;
					&:after {top: 4px;}
				}
				ul {
					padding-top: 3px;
					li {
						a {height: 24px; line-height: 24px;}
					}
				}
			}
		}
		.logo {
			width: 100px; height: 40px; padding: 10px 0 0 0;
		}
		.burger-menu {
			margin: 20px 0 0 5px; width: 22px; height: 20px;
			span {
				width: 100%; height: 4px;
				&:nth-child(2) {top: 8px;}
				&:nth-child(3) {top: 16px;}
			}
			&.expand {
				span {
					&:nth-child(3){top: 16px;}
				}
			}
		}
		&.float {
			height: 60px;
			.menu-top {
				margin: 20px 0 0 0;
				a {
					height: 20px; line-height: 20px; font-size: 12px;
					&.info {display: none;}
				}
				.lang {
					.act {
						padding-top: 0;
						&:after {top: 4px;}
					}
				}
			}
			.logo {
				width: 100px; height: 40px; padding: 10px 0 0 0;
			}
			.burger-menu {
				margin: 20px 0 0 5px; width: 22px; height: 20px;
				span {
					width: 100%; height: 4px;
					&:nth-child(2) {top: 8px; width: 70%;}
					&:nth-child(3) {top: 16px;}
				}
				&.expand {
					span {
						&:nth-child(3){top: 16px;}
					}
				}
			}
		}
	}

	.breadcrumb {display: none;}

	.nav-mobile {
		padding: 60px 0 30px 0; text-align: center;
		.navmob {
			padding: 0;
			ul {
				padding: 35px 0 20px 0;
				li {
					ul {height: 60%;}
					a {font-size: 16px; padding: 13px 0;}
				}
			}
			.subdrop, .subdrop-2 {
				padding: 0 15px; height: 95%; top: 60px;
	            .title {
	            	font-size: 17px; font-weight: bold; color: $c_green_10; margin-bottom: 20px;
	            	a {font-weight: bold; font-size: 16px;}
	            }
	            .back, .back-2 {
	                margin: 15px 0 0 0;
	                a {
	                    padding: 0;	width: 26px; height: 26px; background-size:100% 100%;
	                }
	            }
	            li {

	                a {
	                    padding: 10px 0; font-size: 16px;
	                }
	            }
	        }
		}
		.social-mob {
			ul {
				li {
					padding: 0 5px;
					a {
						width: 20px !important; height: 17px !important;
						background-size: auto 100% !important;
					}
				}
			}
		}
		.info-mob {
			display:inline-block;
		}
	}

	.middle {
		padding-top: 60px;
	}

	.homeslider {
		.caption {
			width: 100%; padding: 0 20px; left: 0;
			@include transform(translate(0, -50%));
			@include boxSizing(border-box);
			.title {font-size: 22px; line-height:26px; margin-bottom: 10px;}
			.des {font-size: 14px; margin-bottom: 20px;}
		}
	}

	.col-logo {
		padding: 15px 0;
		.row {
			.col {
				width: 17%; margin: 0 1%;
				a {
					height: 22px; text-align: center; display: block;
					img {left: 0; @include transform(translate(0,-50%));}
				}
			}
		}
	}
	.col-box {
		h2 {
			font-size: 16px; margin-bottom: 10px; padding-left: 0;
			span {font-size: 70px; top: -30px; left: 0; margin-left: 0;}
			&.say {margin: 0;}
			&.land {
				span {left: 0;}
			}
			&.green {
				span {margin-left: 0;}
			}
		}
		h3 {
			font-size: 25px; line-height: 30px;
			&.v2 {line-height: 28px;}
		}
	}

	.home-about {
		.container {padding: 50px 0;}
		.text {
			width: 100%;
			p {font-size: 14px; line-height: 20px;}
			ol {
				li {
					font-size: 13.5px; padding: 3px 0 3px 25px;
					&:after {
						left:17px;
					}
				}
			}
		}
	}

	.listing-news {
		.container {padding: 50px 0;}
		.row {
			a {min-height: 320px;}
			.col {
				width: 100%; margin: 0 0 15px 0;
				&.has-img {
					.img {
						img {width: 100%;}
					}
				}
			}
		}
		&.v2 {
			.row {
				.col {
					&.abs-img {
						.inn {padding: 25px 20px;}
					}
				}
			}
		}
		&.carousel {
			.slick-list {
				margin: 0 40px;
			}
			.slick-arrow {
				width: 28px; height:28px;
				background-size: auto 100% !important;
				&.slick-next {
					background-position: -28px 0 !important;
					&.slick-disabled {background-position: -28px 0 !important;}
				}
				&.slick-prev {
					background-position: 0 0 !important;
					&.slick-disabled {background-position: 0 0 !important;}
				}
			}
			.row {
				.col {
					margin: 0 0 10px 0;
					&:nth-child(4n+4) {margin-right: 0%; display: inline-block;}
					&:nth-child(3n+3) {margin-right: 0%;}
				}
			}
		}
	}

	.box-news {
		.row {
			.col {
				width: 100%; right: 0;
				.text {
					padding: 20px;
					.title {
						font-size: 14px; line-height: 16px;
					}
				}
				.img {
					&:after {background: url("../images/material/bg_shadow.png") repeat-x 0 bottom;}
				}
				&.large {
					.text {
						.title {
							font-size: 14px; line-height: 16px;
						}
					}
					a {
						&:before {width: 40px; height: 30px; background-size:100%;}
					}
					.img {
						&:after {background: url("../images/material/bg_shadow.png") repeat-x 0 bottom;}
					}
					&.vids {
	                    a {
	                        &:before {
	                            width: 50px; height: 38px;
	                        }
	                    }
	                }
				}
			}
		}
	}

	.home-testi {
		.container {
			padding: 50px 0;
			&:after {display:none;}
		}
		.txt-testi {
			padding: 0 0 15px 0;
			a {
				&:after {
					top:0; left: 0; width: 30px; height: 31px;
				}
			}
			.ts-title {
				padding: 40px 0 0 0;
			}
		}
		.slick-slider {
			margin: 0;
			.slick-dots {bottom: -25px;}
		}
	}

	.home-cta {
		position: relative; height: auto;
		&:before {
			position: absolute; top: 0; left: 0; width: 100%; height: 100%; content: "";
			z-index: 2; background: rgba(51,58,67, 0.95);
		}
		.container {padding: 50px 0; z-index: 3;}
		.text {
			width: 100%;
			h3 {font-size: 20px; line-height: 25px;}
		}
	}

	.home-address {
		.container {padding: 50px 0;}
		.col {
			h2 {line-height: 22px;}
			&.des {
				padding-bottom: 15px;
				p {line-height: 20px;}
			}
			&.address {
				.row {
					.item {
						width: 100%; margin: 0;
						&:before {top:3px;}
						@include boxSizing(border-box);
					}
				}
			}
		}
	}

	.breadcrumb {
		ul {
			li {
				margin-bottom: 5px; font-size: 14px;
				a {padding: 0 3px 0 3px;}
				&:first-child {
					a {padding-left:0;}
				}
			}
		}
	}

	.slick-dots {
		bottom: 10px; margin: 0;
		li {
			margin: 0 3px;
			button {width: 10px; height: 10px;}
		}
	}
	.slick-arrow {
		width: 38px; height: 38px;
		&:after {width: 24px; height: 24px; background-size:24px 24px !important;}
	}

	.box-title {
		min-height: 180px;
		.container {padding: 30px 0;}
		h1 {font-size: 16px; line-height: 20px;}
		.container {padding: 35px 0 0 0;}
		&.v2 {
			margin-bottom: 40px;
		}
	}

	.mid-content {
		padding-bottom: 30px; font-size: 14px;
		.banner-slider {
			margin: -186px 0 60px 0;
			&.v2 {margin-bottom: 40px;}
			&.v3 {margin-bottom: 40px;}
			&.v4 {
				.img {
					&:before {opacity: 0.4;}
				}
			}
			&.v5 {margin-top: -87px; margin-bottom: 30px;}
			.caption {
				padding: 0 15px 40px 15px; text-align: center; font-size: 18px; line-height: 22px;
			}
			&.creer {
				margin: 30px 0;
			}
		}
		.banner-testimoni {
			margin: -186px 0 60px 0;
			max-height: 100%; position: static;
			.img {margin-bottom: 30px;}
			.slick-dots {margin-top: -15px;}
		}
		.box-landing {
			.row {
				margin-bottom: 60px;
				.col {
					width: 100%; margin: 0 0 15px 0;
					.ld-des {
						.ct {font-size: 14px;}
					}
					&.txt {width: 100%; padding: 0;}
					&.img {width: 100%; padding: 0; margin-bottom: 0;}
					.land-banner {
						.slide {
							.img {
								img {width: 100%;}
								&.v2 {
									&:before {
										background-position:0 bottom;
									}
								}
							}
						}
						.slick-initialized {
							.slick-list {
								&:after {width: 115px; height: 50px; background-size:cover !important;}
							}
							.slick-arrow {
								width: 38px; height: 38px; top: 20px;
								&:after {width: 38px; height: 38px; background-size: 38px 38px !important;}
								&.slick-prev {right:20px; margin-left: -105px;}
								&.slick-next {right:20px;}
							}
						}
						.caption {
							&.v2 {
								padding: 0 20px; font-size: 18px; line-height: 20px;
								a {font-size: 14px;}
							}
						}
					}
				}
				&:nth-child(even) {
					.col {
						&.txt {padding: 0;}
					}
				}
			}
		}
		p {
			line-height: 22px; font-size: 14px;
		}

		.paging {
			div {
				a {
					padding: 3px 7px 3px 7px; font-size: 16px;
					&:after {right:10%;}
					&.prev, &.next {
						padding: 0; width: 25px; height: 25px; background-size:auto 25px;
						margin: 0 10px;
					}
					&.prev {
						&:hover {background-position:0 0;}
					}
					&.next {
						background-position: -25px center;
						&:hover {background-position:-25px 0;}
					}
				}
			}
		}

		.description {
			h3 {
				font-size: 22px; line-height: 30px;
				&.v2 {font-size: 20px; line-height: 26px;}
			}
			h4 {font-size: 16px; line-height: 26px;}
			h5 {
				span {display: block; margin-bottom: 10px;}
			}
			.htop {
				a {font-size: 13px; margin-top: 5px;}
			}
			.table-std {position: relative; overflow: auto; padding: 10px 0 0 0; margin-bottom: 35px;}
			table {
				thead {
					tr {
						th {padding: 10px 5px; font-size: 13px;}
						&.med {
							th {font-size: 13px;}
						}
					}
				}
				tbody {
					tr {
						td {font-size: 12px; padding: 10px 5px;}
					}
				}
				tfoot {
					tr {
						td {font-size: 12px; padding: 10px 5px;}
					}
				}
			}
			ul {
				margin-left: 0;
				li {
					line-height: 20px;
					&:before {
						top:7px;
					}
					ul {margin-left: 0;}
				}
				&.space {
					margin-left: 5px;
				}
			}
			.photo-profile {
				padding: 0 0 10px 0;
				.col {
					&.img {
						width: 100%; text-align: center; margin-bottom: 15px;
						img {width: 145px;}
					}
					&.text {
						width: 100%; padding: 0; text-align: center;
						h3 {font-size: 24px;}
						h4 {font-size: 13px; line-height: 18px;}
						p {font-size: 13px;}
						&.v2 {
							padding-right: 0; padding-top: 0;
						}
					}
				}
			}
			.listing-headschool {
				.photo-profile {padding: 0 0 70px 0;}
				.row {
					.abs {
						top: 100%; margin-top: -65px; left: 50%; padding: 0 15px; text-align: center;
						@include transform(translate(-50%));
					}
				}
			}
			.indes {
				padding: 0;
			}
			&.nostyled-table {
				table {width: 100%;}
			}
			.slick-dots {
				margin: 0;
				li {line-height: 10px;}
			}

			.col-bottom {
				padding: 60px 0 0 0;
				.row {
					&:before {display:none;}
					.cl {
						&.fl {
							width: 100%; padding: 0 0 0 0; margin-bottom: 30px;
							&:after {
								position: absolute;
							    width: 100%;
							    height: 1px;
							    content: "";
							    display: block;
							    background: #dddddd;
							}
						}
						&.fr {width: 100%; padding: 0 0 0 0;}
					}
				}
			}
			.list-life {
				.row {
					.col {
						padding: 0 0 20px 0; margin: 0 0 20px 0;
						.fl {width: 100%; margin-bottom: 15px;}
						.fr {width: 100%;}
						h5 {font-size: 14px; margin-top: 0;}
						a {height: 30px; line-height: 30px; padding: 0 20px;}
					}
				}
			}
			.testi {
				.photo-profile {
					padding-bottom: 20px;
					.col.text.v2 {
						padding: 0 10px;
						p {font-size: 13px; line-height: 22px;}
					}
				}
			}
		}

		.grid-category {
			padding: 0;
			.row {
				.col {
					width: 100%; padding: 0; margin: 0 0 20px 0;
					&:nth-child(2n+2) {padding-left: 0;}
					&.first {
						.caption {
							width: 49%; padding: 0 15px; font-size: 16px; line-height: 18px;
							.des {font-size: 13px; line-height: 15px; padding: 5px 0 0 0;}
						}
					}
				}
			}
			&.col_3 {
				.row {
					.col {width: 100%;}
				}
			}
		}

		.list-gen {
			.col {
				padding: 15px 0;
				.fl {width: 100%; margin: 0 0 10px 0;}
				.fr {
					width: 100%; padding: 0; text-align: left;
				}
			}
		}

		.col-university, .col-partner {
			.row {
				margin-bottom: 30px;
				.col {
					h5 {margin-bottom: 20px;}
					div {
						a {padding: 0 20px 0 20px; width: 50%; height: 100px;}
					}
				}
				&.col-5 {
					.col {
						a {width: 50%;}
					}
				}
			}
		}

	}



	.col-form {
		.container {
			&:before {
				width:65px; height: 85px; right: 0; top: -40px;
				background-size: 100%;
			}
		}
		.form-std {
			padding: 0;
			.col {
				width: 100%; padding: 0 0 0 0; margin: 0 0 30px 0;
				&:nth-child(2n+2) {margin-right: 0; padding-left: 0;}
				.label {
					height: 20px; line-height: 20px; font-size: 14px; top: 12px;
				}
				.anim-label {font-size: 14px;}
				.field {
					padding-bottom: 10px;
					&.other-val {
						padding-top: 10px;
					}
				}
	            &.with-padding{
	            	padding-left:0;
	            }
	            &.no-padding{
	            	padding:0;
	            }
	            &.col-button{
	            	padding:0;
	            }
	            &.col-captcha .g-recaptcha{
	            	transform: scale(0.93);
				    transform-origin: 0;
				    margin-bottom: -5px;
	            }

			}
			&.std-2 {
				.col {
					margin-bottom: 15px;
					.btn {margin: 0 auto;}
				}
			}
		}
		&.v2 {
			.form-std {
				.col {
					.label {font-size: 14px;}
					.field.focus .label {font-size: 12px;}
				}
			}
		}
	}

	.col-acc {
		.acclist {
			.acc-title {
				padding: 15px 30px 15px 0px; font-size: 14px;
				&:after {right:8px; top: 20px;}
			}
			.acc-descrip {
				ol {
					li {font-size: 14px; line-height: 18px;}
				}
			}
		}
		&.v2 {
			.acc-descrip {
				padding: 15px 0;
			}
		}
	}

	.tab-container {
		.nav-tab {
			padding-bottom: 30px;
			.parent-mob {
				display: block;
			}
			ul {
				display: none;
				li {
					display: block; margin-bottom: 1px;
					&:after {display:none;}
					a {
						font-size: 14px; padding: 15px 15px; background:$c_e1e;
						&:after {display:none;}
					}
					&:first-child {
						a {
							padding-left: 15px;
							&:after {left:50%;}
						}
					}
				}
			}
		}
	}

	.col-gallery {
		.nav-gal {
			text-align: center; margin-bottom: 25px;
			a {font-size: 18px; margin-right: 30px; float: none; display: inline-block;}
		}
		.row {
			.col {
				width: 100%; margin: 0 0 3% 0;
				a {
					.text {
						padding: 18px;
						.caption {font-size: 16px; line-height: 18px; height: auto;}
					}
					.img {
						&:before {background-position:0 bottom; opacity: 0.9}
						.hov_btn {
							.btn {font-size: 12px; line-height: 30px; height: 30px; padding: 0 25px;}
						}
					}
				}
			}
		}
	}

	.inner-contact {
		&.v2 {padding: 50px 0;}
		.list-address {
			.row {
				.col {
					h3 {
						font-size: 22px;
						&.v2 {font-size: 20px;}
					}
					h4 {font-size: 18px;}
					p {
						font-size: 16px; line-height: 26px;
						.green {font-size: 15px; line-height: 24px;}
					}
					.row {
						margin-bottom: 30px;
						.cl {
							&:nth-child(1) {width: 100%;}
							&:nth-child(2) {width: 100%;}
						}
					}
				}
			}
		}
	}

	.inner-contact {
		.list-map {
			h3 {margin-bottom: 20px;}
			.row {
				.col {
					width: 100%; margin: 0 0 20px 0;
				}
			}
		}
		.addmission {
			h2 {margin-bottom: 20px;}
			.row {
				margin-bottom: 20px;
				.col {
					width: 100%; margin-bottom: 5px; font-size: 16px;
					&:nth-child(2n+2) {width: 100%; padding: 0; font-size: 14px;}
				}
			}
		}
	}

	.careermap {
		.middle {min-height: 480px; max-height: 480px;}
		.container {width: 240px; margin: auto;}
		header {
			.container {width: auto; margin: 0 20px;}
		}
		.des-map {
			min-height:260px; max-height: 260px;
			.title {font-size: 22px; line-height: 28px; padding-top: 40px;}
			p {font-size: 13px; display: none;}
		}
		.point-map {
			a {
				font-size: 9px; padding: 30px 0 0 0;
				&:after {width: 23px; height: 28px; background-size: 100%;}
				&.jkt {left:-70px; top: -48px;}
				&.bali {right:40px; top: 0;}
			}
		}
	}

	.wrap-tankyou {
		min-height: 500px;
		.container {width: auto;}
	}


	#boxPop {
		.popArrow {
			width: 38px; height: 38px;
			&:after {width: 25px; height: 25px; background-size:100% 100% !important;}
		}
		.close_pop {
			width: 18px; height: 18px; background-size: 100%; top: -35px;
		}
		.content_pop {
			.detTxt {
				.detCate {font-size: 13px;}
				.detTitle {font-size: 20px;}
			}
			.vid {height: 200px;}
		}
	}

	.filtering {
		.cl {display: block;}
		.fr {width: 100%;}
	}

	.popup {
    .box-popup {
			max-width: 380px;
			padding: 10px;
			.content-popup {
				padding: 0 10px;
				h4 {
					font-size: 16px;
				}
				p {
					font-size: 12px;
					line-height: 18px;
					margin-bottom: 15px;
				}
        ol li, ol li:before, ul li {
          font-size: 12px;
          line-height: 18px;
        }
        ol li {
          padding-left: 15px;
        }
        ul li {
          padding-left: 15px;
          &::before {
            height: 6px;
            top: 6px;
            width: 6px;
          }
        }
			}
			button {
				width: 140px;
				padding: 8px 0;
				font-size: 11px;
			}
    }
	}

	footer {
		.ftop {
			.fl {
				width: 65%; padding: 0 10px 0 0;
				@include boxSizing(border-box);
				.cl {
					width: 100%; @include boxSizing(border-box); margin: 0 auto 6px auto; float: none;
					padding: 3px 0px 5px 34px; font-size: 10.5px; line-height: 14px;
					&.mail {width: 100%;}
				}
			}
			.fr {
				width: 35%;
				.social-foot {
					width: 100%; text-align: right;
					a {padding: 0 2px; text-indent: 999999px; margin-bottom: 6px;}
				}
			}
		}
		.copyright {margin: 0;}
		.fbot {
			text-align: center;
			.fl {width: 100%; margin-bottom: 10px;}
			.fr {width: 100%;}
		}
	}
	.scrollup {
		bottom: 25px; right: 20px; width: 25px; height: 25px;
		background-size:50% auto !important;
	}
  .login{
    .wrapper{
      margin: 0 20px;
      .side-form{
        .content-form{
          .heading{
            figure{
              > img{
                width: 150px;
              }
            }
            h6{
              font-size: 15px;
              padding-left: 16px;
              &::before{
                left: 8px;
              }
            }
          }
          h2{
            text-align: center;
          }
        }
      }
    }
  }
  .mid{
    .row{
      margin-left: unset;
      width: auto;
      .column.column-33{
        padding: 0 10px;
      }
      .column{
        &.column-65, &.column-35, &.column-50, &.column-25, &.column-75, &.column-100{
          flex: 0 0 100%;
          max-width: 100%;
          padding: 0;
        }
      }
			&.row-split {
				width: calc(100% + 20px);
				margin-left: -10px;
				.column {
					&.column-25 {
						flex: 0 0 40%;
						max-width: 40%;
						padding: 0 10px;
					}
					&.column-50 {
						flex: 0 0 50%;
						max-width: 50%;
						padding: 0 10px;
					}
					&.column-75 {
						flex: 0 0 60%;
						max-width: 60%;
						padding: 0 10px;
					}
				}
			}
    }
    .nav-mobile-eca{
      display: none;
    }
    .side-right{
      padding-left: 0;
      &.moveright{
        padding-left: 0;
      }
      .wrap-day{
        position: relative;
      }
      .payment{
        .payment-detail{
          .box-payment{
            padding: 25px 25px 110px;
						select {
							width: calc(100% - 50px);
							left: 25px;
							right: unset;
							top: unset;
							bottom: 45px;
						}
          }
        }
        .accordion-payment{
					border-bottom: unset;
          .acclist{
            .acc-title{
							font-size: 20px;
              padding: 24px 0;
							border-top: unset;
							border-bottom: 1px solid #ccc;
							.logo-payment {
								display: none;
							}
            }
            .acc-descrip{
							padding: 25px 0;
              .form-control{
                input[type=number]{
                  width: 100%;
                }
                > img{
                  width: 100%;
                }
              }
              .wrap-button{
                display: block;
                .btn-cancel, .btn-submit{
                  width: 100%;
                  margin-bottom: 10px;
                }
              }
            }
          }
        }
      }
      .summary{
        right: 18px;
        left: 18px;
        bottom: 18px;
        width: calc( 100% - 36px );
        h3{
          &:first-child{
            display: none;
          }
        }
        .btn-yellow{
          font-size: 0;
          width: 76px;
        }
      }
      .cart{
        .input-quantity{
          justify-content: end;
        }
        .cart-category{
          margin-bottom: 34px;
        }
        table{
          border-bottom: 1px solid #E6E6E6;
          td,th{ display: block;}
			    thead{ display: none;}
          tr{
            position: relative;
            display: flex;
            flex-flow: wrap;
            margin-bottom: 24px;
          }
          td{
            padding: 0;
            &:first-child{
              top: 30px;
              .custom-checkbox{
                top: 30px;
              }
            }
            &:nth-child(2){
              width: 80px;
            }
            &:nth-child(3){
              width: calc( 100% - 32px - 80px - 18px );
              padding-left: 6px;
              padding-top: 20px;
              position: relative;
              margin-bottom: 14px;
              &::after{
                content: "Item Name";
                font-size: 14px;
                color: #666666;
                font-weight: 600;
                position: absolute;
                top: 0;
                left: 6px;
              }
              h6{
                height: 17px;
                overflow: hidden;
              }
            }
            &:nth-child(4){
              width: 100%;
              order: 5;
              position: relative;
              &::after{
                content: "QTY";
                font-size: 14px;
                color: #666666;
                font-weight: 600;
                position: absolute;
                top: 50%;
                left: 32px;
                transform: translateY(-50%);
              }
            }
            &:nth-child(5){
              width: 100%;
              order: 6;
              background: #F5F5F5;
              border-radius: 4px;
              padding: 5px 24px;
              text-align: right;
              position: relative;
              &::after{
                content: "Total Price";
                font-size: 14px;
                color: #666666;
                font-weight: 600;
                position: absolute;
                top: 50%;
                left: 32px;
                transform: translateY(-50%);
              }
            }
            &:last-child{
              width: 18px;
              order: 4;
            }
          }
        }
      }
      .invoice{
        .recipient{
          .grid-l, .grid-r{
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
        .notes{
          .total{
            text-align: left;
            margin-bottom: 24px;
          }
        }
        .payment-detail{
          margin-bottom: 24px;
          .thead{
            .flex-grid{
              .grid-r{
                display: none;
              }
              .grid-l{
                padding-left: 6px;
              }
            }
          }
          .tbody{
            padding: 20px 0;
            .flex-grid{
              display: block;
              padding-bottom: 20px;
              .grid-r, .grid-l{
                flex: 0 0 100%;
                max-width: 100%;
                padding: 0 6px;
                text-align: left;
              }
            }
          }
        }
      }
      .school-item{
        .wrap-items{
          margin-bottom: 24px;
          figure{
            height: 132px;
            img{
              height: 100%;
            }
          }
          figcaption{
            padding: 12px;
            min-height: 90px;
            h6{
              font-size: 14px;
            }
            h5{
              font-size: 16px;
            }
          }
          .overlay-cart{
            display: none;
          }
          &.action{
            .overlay-cart{
              position: fixed;
              top: unset;
              bottom: 0;
              opacity: 1;
              z-index: 9;
              height: auto;
              min-height: 380px;
              display: block;
            }
          }
        }
      }
      .title-bar{
        display: block;
        min-height: unset;
        h3{
          font-size: 20px;
          margin-bottom: 0px;
        }
        .search{
          margin-top: 25px;
        }
      }
      .tab-item{
        margin-bottom: 35px;
        ul{
          li{
            margin-right: 24px;
          }
        }
      }
      .wrapper{
        margin: 0 12px;
      }
      .top-bar{
        margin: 0 -12px 12px;
        background: #ECF4F4;
        padding: 50px 0 6.5px 0;
        .flex-top{
          margin: 0 20px;
          .burger-menu-eca{
            display: block;
          }
          .side-logo{
            width: calc( 100% - 24px - 34px );
            justify-content: center;
            figure{
              flex: 0 0 113px;
              max-width: 113px;
            }
            h6{
              display: none;
            }
          }
          .side-btn{
            width: 24px;
            a{
              display: none;
            }
						.box-notif {
							width: 100%;
							height: 100%;
							max-height: unset; 
							left: 0;
							position: fixed;
							top: 0;
							z-index: 999999;
							.close-notif {
								display: block;
								width: 30px;
								top: 7px;
								right: 7px;
								position: absolute;
							}
						}
          }
        }
      }
    }
  }
  .only-mobile{
    display: block;
  }

  .logout-mobile{
    padding: 12px 0;
    text-align: center;
    border: 1px solid #EABA2B;
    border-radius: 6px;
    color: #EABA2B;
    font-weight: 700;
    font-size: 14px;
    margin-top: 30px;
  }
  .eca-top{
    .wrapper{
      margin: 0 34px;
      .container{
        .heading-txt{
          width: 100%;
          h2{
            font-size: 28px;
          }
          p{
            font-size: 16px;
          }
        }
      }
    }
    .container{
      margin: 0;
    }
    .row{
      .column.column-30, .column.column-10,.column.column-20{
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 12px;
      }
      .column.column-10{
        margin-top: 20px;
        margin-bottom: 0;
      }
    }
  }
  .eca-content{
    .wrapper{
      margin: 0 16px;
      .wrap-rounded{
        .content{
          padding: 30px 20px;
          .title-bar{
            display: block;
            h3{
              margin-bottom: 24px;
              font-size: 20px;
            }
          }
          .regist-date{
            display: block;
            h6{
              margin: 0;
              margin-top: 10px;
            }
          }
          .content-centered{
            padding: 0;
            display: block;
            .content-img{
              margin-bottom: 24px;
            }
          }
          .list-eca{
            &.registered{
              .table-std{
                tbody{
                  tr{
                    td{
                      padding: 0;
                      padding-top: 8px;
                      &:first-child{
                        position: inherit;
                        display: flex;
                        padding-top: 8px;
                        &::before{
                          display: block;
                        }
                      }
                      &:nth-child(2){
                        padding-top: 8px;
                        color: #5A5A5A;
                        font-weight: 600;
                      }
                      &:nth-child(3){
                        font-weight: 400;
                      }
                    }
                  }
                }
              }
            }
            .table-std{
              width: 100%;
              overflow: hidden;
              td,th{ display: block;}
              thead{
                tr{
                  position: relative;
                }
                th{
                  display: none;
                  padding: 0;
                  padding-bottom: 27px;
                  &:first-child{
                    display: block;
                    position: relative;
                  }
                  &:before{
                    content: attr(data-title);
                    position: absolute;
                    display: block;
                    left: 30px;
                    top: 2px;
                    color: #0F6961;
                    font-weight: 600;
                  }
                }
              }
              tbody{
                border-top:1px solid #D9D9D9;				
                tr{
                  position: relative;
                  border-bottom:1px solid #D9D9D9;				
                  td{
                    padding: 0;
                    margin: 0;
                    padding: 8px 0 0 30px;
                    display: flex;
                    line-height: 1.4;
                    // width: calc( 100% - 30px );
                    &:first-child{
                      position: absolute;
                      padding: 0;
                      padding-top: 20px;
                      left: 0;
                      display: block;
                      &::before{
                        display: none;
                      }
                    }
                    &:nth-child(2){
                      padding-top: 20px;
                      color: #0F6961;
                      font-weight: 600;
                    }
                    &:last-child{
                      padding-bottom: 16px;
                    }
                    &:before{
                      content: attr(data-title);
                      position: relative;
                      color: #444444;
                      font-weight: 600;
                      display: block;
                      width: 50%;
                    }
                  }
                }
              }
            }
            table {
              position: relative;
            }
          }
          .list-eca-foot{
            display: block;
            text-align: center;
            span{
              margin-bottom: 12px;
              display: block;
            }
            .btn-yellow{
              width: 100%;
            }
          }
        }
      }
    }
  }
  .head-eca{
    height: unset;
    .container{
      padding: 54px 0 10px;
      .heading{
        width: 100%;
        figure{
          flex: 0 0 107px;
          max-width: 107px;
        }
      }
    }
    &.float{
      height: auto;
      .container{
        .heading{
          figure{
            flex: 0 0 107px;
            max-width: 107px;
          }
        }
      }
    }
  }
  .popup{
    &.popup-success{
      .box-popup{
        padding: 10px;
        figure{
          img{
            width: 153px;
          }
        }
        .wrap-btn-pdf{
          .btn-pdf{
            width: 100%;
          }
        }
      }
    }
  }
}


@media all and (max-width: 479px) {

	header {
		.menu-top {
			.lang {margin-right: 12px;}
			.login {padding-right: 15px !important;}
		}
	}

	.mid-content {
		.banner-slider {
			.caption {
				padding: 0 40px 40px 40px; font-size: 16px; line-height: 20px;
			}
		}
	}

	.careermap {
		.point-map {
			a {
				&.jkt {left: -10px;}
				&.bali {right: 25px;}
			}
		}
		.middle {
			background: url('../images/material/bg-map-mob.png') no-repeat center 0 !important;
			background-size: 435px auto !important;
		}
	}
	.home-cta {
		.bg_shd {right:0; background-size: cover;}
	}

	/*.box-news {
		.row {
			.col {
				width: 100%; right: 0;
				.text {
					padding: 20px;
					.title {
						font-size: 14px; line-height: 16px;
					}
				}
				.img {
					&:after {
						opacity:0.75;
					}
				}
				&.large {
					.img {
						&:after {background:url("../images/material/overlay-thumb.png") repeat-x 0 bottom; opacity: 0.75;}
					}
				}
			}
		}
	}*/
	footer {
		.ftop {
			.fl {
				width: 76%;
			}
			.fr {
				width: 24%;
			}
		}
		.copyright {font-size: 10px;}
	}

	.wrap-tankyou {
		p {font-size: 14px; line-height: 28px;}
		&.w404 {
			h1 {font-size: 80px;}
		}
	}

}
